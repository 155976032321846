


import http from '../axios'


// 首页的杂志列表
export const $getEzine = (params) => {
    return http.post('/home/the/press/list', params)
}

// 详情页的接口
export const $getEzineDetail = (id => {
    return http.post('https://static.gt0571.com/api/the/press/list', { id })
})

// 电子报刊分页列表
export const $getEzineForPage = params => {
    return http.post('/the/press/page/list', params)
}

// 获取已经收藏的杂志列表
export const $getCollectedEzineList = params => {
    return http.post('/press/collect', {
        token: localStorage.getItem('token'),
    })
}